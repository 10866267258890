import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { api } from "api";
import { useAuth } from "hooks/useAuth";
import { routes } from "routes";
import {
  DocumentType,
  useQueryCustomDocumentTypes,
  getLatestDocumentByStatusByType,
} from "pages/documents";
import { ReactComponent as Delimited } from "assets/icons/Sidebar/arrowDown.svg";
import { ReactComponent as CheckIcon } from "assets/icons/checkCurrentColor.svg";
import { ReactComponent as XIcon } from "assets/icons/xIconCurrentColor.svg";

import styles from "./OnboardingBreadcrumbs.module.scss";

export function OnboardingBreadcrumbs({ spaceBetween = false }) {
  const { fleetSlug, documentTypeId } = useParams();
  const { t } = useTranslation();

  const { data } = api.endpoints.getMarketingPageByFleetSlug.useQuery({
    fleetSlug,
  });
  const fleetId = data?.data?.fleet_id ?? null;
  const { user } = useAuth();

  const currentDriverApplication = useMemo(() => {
    return user?.driver_applications?.find(
      (driverApplication) => driverApplication?.fleet_id === fleetId
    );
  }, [user, fleetId]);

  const {
    predefinedDriverDocumentTypes,
    customApplicationDriverDocumentTypes,
    customApplicationOnlyDocumentTypes,
    predefinedVehicleDocumentTypes,
    customApplicationVehicleDocumentTypes,
    documentTypes,
  } = useQueryCustomDocumentTypes({ currentCompanyId: fleetId });

  const completes = useBreadcrumbsCompletes({
    documentTypes,
    currentDriverApplication,
  });
  const statuses = useBreadcrumbsStatuses({ documentTypes });

  function getStatus(type) {
    const isApplicationSubmitted =
      typeof currentDriverApplication?.status === "string" &&
      currentDriverApplication.status !== "in_progress";

    const isApplicationRejected =
      currentDriverApplication?.status === "rejected";

    if (type === "submit" && isApplicationRejected) {
      return "rejected";
    }

    if (type === "submit" && isApplicationSubmitted) {
      return "completed";
    }

    if (statuses[type] === "disapproved") {
      return "rejected";
    }

    if (completes[type]) {
      return "completed";
    }

    return "regular";
  }

  return (
    <ul className={clsx(styles.list, { [styles.spaceBetween]: spaceBetween })}>
      {predefinedDriverDocumentTypes.map((documentType, index, array) => {
        const isLast = index === array.length - 1;
        const hasNextDocumentTypes =
          [
            ...customApplicationDriverDocumentTypes,
            ...customApplicationOnlyDocumentTypes,
            ...predefinedVehicleDocumentTypes,
            ...customApplicationVehicleDocumentTypes,
          ].length > 0;

        return (
          <li key={documentType.id} className={styles.item}>
            <Breadcrumb
              to={routes.onboardingDocument.getURL({
                fleetSlug,
                documentTypeId: documentType.id,
              })}
              number={index + 1}
              current={documentTypeId === String(documentType.id)}
              status={getStatus(documentType.id)}
            >
              {documentType.name}
            </Breadcrumb>

            {!isLast || hasNextDocumentTypes ? (
              <Delimited className={styles.delimiter} />
            ) : null}
          </li>
        );
      })}

      {customApplicationDriverDocumentTypes.map(
        (documentType, index, array) => {
          const isLast = index === array.length - 1;

          const hasNextDocumentTypes =
            [
              ...customApplicationOnlyDocumentTypes,
              ...predefinedVehicleDocumentTypes,
              ...customApplicationVehicleDocumentTypes,
            ].length > 0;

          return (
            <li key={documentType.id} className={styles.item}>
              <Breadcrumb
                to={routes.onboardingDocument.getURL({
                  fleetSlug,
                  documentTypeId: documentType.id,
                })}
                number={predefinedDriverDocumentTypes.length + index + 1}
                current={documentTypeId === String(documentType.id)}
                status={getStatus(documentType.id)}
              >
                {documentType.name}
              </Breadcrumb>

              {!isLast || hasNextDocumentTypes ? (
                <Delimited className={styles.delimiter} />
              ) : null}
            </li>
          );
        }
      )}

      {customApplicationOnlyDocumentTypes.map((documentType, index, array) => {
        const isLast = index === array.length - 1;

        const hasNextDocumentTypes =
          [
            ...predefinedVehicleDocumentTypes,
            ...customApplicationVehicleDocumentTypes,
          ].length > 0;

        return (
          <li key={documentType.id} className={styles.item}>
            <Breadcrumb
              to={routes.onboardingDocument.getURL({
                fleetSlug,
                documentTypeId: documentType.id,
              })}
              number={
                predefinedDriverDocumentTypes.length +
                customApplicationOnlyDocumentTypes.length +
                index +
                1
              }
              current={documentTypeId === String(documentType.id)}
              status={getStatus(documentType.id)}
            >
              {documentType.name}
            </Breadcrumb>

            {!isLast || hasNextDocumentTypes ? (
              <Delimited className={styles.delimiter} />
            ) : null}
          </li>
        );
      })}

      {predefinedVehicleDocumentTypes.map((documentType, index) => {
        return (
          <li key={documentType.id} className={styles.item}>
            <Breadcrumb
              to={routes.onboardingDocument.getURL({
                fleetSlug,
                documentTypeId: documentType.id,
              })}
              number={
                predefinedDriverDocumentTypes.length +
                customApplicationDriverDocumentTypes.length +
                customApplicationOnlyDocumentTypes.length +
                index +
                1
              }
              current={documentTypeId === String(documentType.id)}
              status={getStatus(documentType.id)}
            >
              {documentType.name}
            </Breadcrumb>

            <Delimited className={styles.delimiter} />
          </li>
        );
      })}

      {customApplicationVehicleDocumentTypes.map((documentType, index) => {
        return (
          <li key={documentType.id} className={styles.item}>
            <Breadcrumb
              to={routes.onboardingDocument.getURL({
                fleetSlug,
                documentTypeId: documentType.id,
              })}
              number={
                predefinedDriverDocumentTypes.length +
                customApplicationDriverDocumentTypes.length +
                customApplicationOnlyDocumentTypes.length +
                predefinedVehicleDocumentTypes.length +
                index +
                1
              }
              current={documentTypeId === String(documentType.id)}
              status={getStatus(documentType.id)}
            >
              {documentType.name}
            </Breadcrumb>

            <Delimited className={styles.delimiter} />
          </li>
        );
      })}

      <li className={styles.item}>
        <Breadcrumb
          to={routes.onboardingDocument.getURL({
            fleetSlug,
            documentTypeId: "submit",
          })}
          number={
            predefinedDriverDocumentTypes.length +
            customApplicationDriverDocumentTypes.length +
            customApplicationOnlyDocumentTypes.length +
            predefinedVehicleDocumentTypes.length +
            customApplicationVehicleDocumentTypes.length +
            1
          }
          current={documentTypeId === "submit"}
          status={getStatus("submit")}
        >
          {t("action.submit")}
        </Breadcrumb>
      </li>
    </ul>
  );
}

function Breadcrumb({
  to,
  children,
  number,
  current = false,
  restricted = false,
  status,
}) {
  if (restricted) {
    return (
      <div className={styles.breadcrumb}>
        <BreadcrumbCoin status={status} current={current}>
          {number}
        </BreadcrumbCoin>
        <div
          className={clsx(styles.breadcrumbText, { [styles.current]: current })}
        >
          {children}
        </div>
      </div>
    );
  }

  return (
    <Link to={to} className={styles.breadcrumb}>
      <BreadcrumbCoin status={status} current={current}>
        {number}
      </BreadcrumbCoin>
      <div
        className={clsx(styles.breadcrumbText, { [styles.current]: current })}
      >
        {children}
      </div>
    </Link>
  );
}

function BreadcrumbCoin({ status, current, children }) {
  const regular = status === "regular";
  const completed = status === "completed";
  const rejected = status === "rejected";

  return (
    <div
      className={clsx(styles.coin, {
        [styles.regular]: regular,
        [styles.current]: current,
        [styles.completed]: completed,
        [styles.rejected]: rejected,
      })}
    >
      {completed ? <CheckIcon className={styles.checkIcon} /> : null}
      {rejected ? <XIcon className={styles.xIcon} /> : null}
      {regular ? children : null}
    </div>
  );
}

function useBreadcrumbsCompletes({
  documentTypes,
  currentDriverApplication,
} = {}) {
  const latestDocumentByStatusByType = useLatestDocumentByStatusByType();

  if (!Array.isArray(documentTypes)) return {};

  function getComplete(type) {
    if (type === DocumentType.VEHICLE_DETAILS) {
      return (
        typeof currentDriverApplication?.metadata?.has_own_vehicle === "boolean"
      );
    }
    return Boolean(latestDocumentByStatusByType?.[type]?.last);
  }

  return Object.fromEntries(
    documentTypes.map((type) => {
      return [type.id, getComplete(type.id)];
    })
  );
}

function useBreadcrumbsStatuses({ documentTypes } = {}) {
  const latestDocumentByStatusByType = useLatestDocumentByStatusByType();

  if (!Array.isArray(documentTypes)) return {};

  function getStatus(type) {
    return latestDocumentByStatusByType?.[type]?.last?.status;
  }

  return Object.fromEntries(
    documentTypes.map((type) => {
      return [type.id, getStatus(type.id)];
    })
  );
}

export function useLatestDocumentByStatusByType() {
  const driverApplication = useCurrentDriverApplication();

  return getLatestDocumentByStatusByType(driverApplication?.documents);
}

export function useCurrentDriverApplication() {
  const { fleetSlug } = useParams();
  const { data } = api.endpoints.getMarketingPageByFleetSlug.useQuery({
    fleetSlug,
  });
  const fleetId = data?.data?.fleet_id ?? null;

  const { user } = useAuth();

  return (user?.driver_applications ?? []).find(
    (application) => application.fleet_id === Number(fleetId)
  );
}
