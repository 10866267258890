/**
 * Returns capitalized letter(s) of the first and the last words of the string.
 *
 * @param {string | undefined} name - string to get initials from
 * @param {string} defaultValue - string to return when the name is undefined
 * @returns {string} capitalized letter(s)
 */
export const nameFirstDigits = (name, defaultValue = "") => {
  if (typeof name !== "string") {
    return defaultValue;
  }

  const words = name.split(" ");

  if (words && words.length === 1) {
    return words.at(0).charAt(0).toUpperCase();
  }

  return [words.at(0), words.at(-1)]
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
};
