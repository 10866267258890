import { useSelectedFleet } from "hooks/useSelectedFleet";
import {
  getLatestDocumentByStatusByType,
  useQueryDriverCustomDocumentTypes,
} from "pages/documents";
import { api } from "api";

export function useHasDocumentTypeWithDueToWhichLacksApprovedDocument() {
  const { selectedDriverProfile } = useSelectedFleet();
  const selectedDriverProfileId = selectedDriverProfile?.id;

  const { data: driverDocumentsResponse } =
    api.endpoints.getDriverDocuments.useQuery(selectedDriverProfileId, {
      skip: !selectedDriverProfileId,
    });
  const documents = driverDocumentsResponse?.data;
  const latestDocumentByStatusByType =
    getLatestDocumentByStatusByType(documents);

  const {
    driver_vehicles: driverVehicles = [],
    owner_vehicles: ownerVehicles = [],
  } = selectedDriverProfile;

  const vehicleDocuments = [...driverVehicles, ...ownerVehicles].flatMap(
    (vehicle) => vehicle.documents
  );

  const latestVehicleDocumentByStatusByType =
    getLatestDocumentByStatusByType(vehicleDocuments);

  const { customDriverDocumentTypes, customVehicleDocumentTypes } =
    useQueryDriverCustomDocumentTypes(selectedDriverProfileId);

  const customDriverDocumentTypesWithDeadline =
    customDriverDocumentTypes.filter((type) => type?.with_deadline);

  const customVehicleDocumentTypesWithDeadline =
    customVehicleDocumentTypes.filter((type) => type?.with_deadline);

  const firstDriverDocumentTypeWithDeadlineWhichLacksApprovedDocument =
    customDriverDocumentTypesWithDeadline.find((type) => {
      return (
        typeof latestDocumentByStatusByType[type.id]?.approved === "undefined"
      );
    });
  const atLeastOneDriverDocumentTypeWithDeadlineLacksApprovedDocument = Boolean(
    firstDriverDocumentTypeWithDeadlineWhichLacksApprovedDocument
  );

  const firstVehicleDocumentTypeWithDeadlineWhichLacksApprovedDocument =
    customVehicleDocumentTypesWithDeadline.find((type) => {
      return (
        typeof latestVehicleDocumentByStatusByType[type.id]?.approved ===
        "undefined"
      );
    });
  const atLeastOneVehicleDocumentTypeWithDeadlineLacksApprovedDocument =
    Boolean(firstVehicleDocumentTypeWithDeadlineWhichLacksApprovedDocument);

  return {
    driverDocuments:
      atLeastOneDriverDocumentTypeWithDeadlineLacksApprovedDocument,
    vehicleDocuments:
      atLeastOneVehicleDocumentTypeWithDeadlineLacksApprovedDocument,
  };
}
