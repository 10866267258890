import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo } from "react";
import keyBy from "lodash/keyBy";

import { api } from "api";
import { useCurrentCompanyIdContext } from "../CurrentCompanyIdContext";
import { useAuth } from "./useAuth";
import { changeLanguageDebounced } from "../i18n";

const noCompanies = [];

export const RedirectToCompany = {
  ALL: "ALL",
  DRIVER: "DRIVER",
  ADMIN: "ADMIN",
};

/**
 * Used on the "Fleet admin view" pages (fleet console / legacy pages)
 * or on the Index page (it is coupled with fleet console)
 * or on the StripeKycPage (part of onboarding).
 *
 * Hope, the application scope of this hook will be reduced and in some point
 * it either will be replaced with "fleet console"-specific hook,
 * or it will be deleted.
 */
export function useQueryCompanies({ redirectTo = RedirectToCompany.ALL } = {}) {
  const ldClient = useLDClient();
  const { user, isLoading: isAuthLoading } = useAuth();
  const { data: adminFleetsData, isLoading: areFleetsLoading } =
    api.endpoints.getFleets.useQuery();
  const isLoading = isAuthLoading || areFleetsLoading;

  const driverProfiles = user?.driver_profiles ?? noCompanies;

  const driverCompanies =
    driverProfiles.map((profile) => profile?.fleet) ?? noCompanies;
  const adminCompanies = adminFleetsData?.data ?? noCompanies;

  const {
    driverProfilesByCompanyId,
    adminCompaniesById,
    driverCompaniesById,
    allCompaniesIds,
    driverCompaniesIds,
    adminCompaniesIds,
  } = useMemo(() => {
    const driverProfilesByCompanyId = keyBy(
      driverProfiles,
      (driverProfile) => driverProfile?.fleet?.id
    );
    const driverCompaniesById = keyBy(driverCompanies, "id");
    const adminCompaniesById = keyBy(adminCompanies, "id");

    const driverCompaniesIds = Object.keys(driverCompaniesById);
    const adminCompaniesIds = Object.keys(adminCompaniesById);
    const allCompaniesIds = [
      ...new Set([...driverCompaniesIds, ...adminCompaniesIds]),
    ];

    return {
      driverProfilesByCompanyId,
      adminCompaniesById,
      driverCompaniesById,
      driverCompaniesIds,
      adminCompaniesIds,
      allCompaniesIds,
    };
  }, [driverProfiles, driverCompanies, adminCompanies]);

  const [currentCompanyId, setCurrentCompanyId] = useCurrentCompanyIdContext();

  useEffect(() => {
    const companiesByRedirectTo = {
      [RedirectToCompany.DRIVER]: driverCompaniesIds,
      [RedirectToCompany.ADMIN]: adminCompaniesIds,
      [RedirectToCompany.ALL]: allCompaniesIds,
    };

    const companies = companiesByRedirectTo[redirectTo] ?? [];

    if (
      isLoading ||
      (currentCompanyId !== null && companies.includes(currentCompanyId))
    ) {
      return;
    }
    setCurrentCompanyId(getCurrentCompanyByDriverProfiles(driverProfiles));
  }, [
    driverCompaniesIds,
    adminCompaniesIds,
    allCompaniesIds,
    redirectTo,
    currentCompanyId,
    setCurrentCompanyId,
    driverProfiles,
    isLoading,
  ]);

  const currentCompany = useMemo(() => {
    return (
      driverCompaniesById[currentCompanyId] ??
      adminCompaniesById[currentCompanyId]
    );
  }, [driverCompaniesById, adminCompaniesById, currentCompanyId]);

  useEffect(() => {
    changeLanguageDebounced(currentCompany?.locale);
  }, [currentCompany]);

  useEffect(() => {
    const context = ldClient.getContext();
    const nextContextKey = String(currentCompanyId);

    if (currentCompanyId !== null && context?.key !== nextContextKey) {
      ldClient.identify({
        kind: "fleet",
        key: nextContextKey,
      });
    }
    return () => {
      ldClient.close();
    };
  }, [ldClient, currentCompanyId]);

  useEffect(() => {
    if (currentCompanyId !== null) return;

    const nextCompanyId =
      getCurrentCompanyByDriverProfiles(driverProfiles) ?? allCompaniesIds[0];

    setCurrentCompanyId(nextCompanyId);
  }, [driverProfiles, allCompaniesIds, setCurrentCompanyId, currentCompanyId]);

  const currentCompanyCurrency = useMemo(() => {
    const companyCurrency = currentCompany?.currency;
    if (!companyCurrency) {
      return "--";
    }

    return new Intl.NumberFormat(window.navigator.language, {
      style: "currency",
      currency: companyCurrency,
      currencyDisplay: "narrowSymbol",
    })
      .format(0)
      .replace(/[0-9]./g, "");
  }, [currentCompany]);

  return {
    isLoading,
    adminCompanies,
    driverCompanies,
    driverProfilesByCompanyId,
    adminCompaniesById,
    driverCompaniesById,
    allCompaniesIds,
    currentCompany,
    currentCompanyCurrency,
    currentCompanyId,
    setCurrentCompanyId,
  };
}

export function isProfileCompletelyActive(profile) {
  return (
    profile &&
    profile.stripe_details_submitted &&
    profile.stripe_payouts_enabled
  );
}

export function isProfilePartiallyActive(profile) {
  return profile?.stripe_details_submitted || profile?.stripe_payouts_enabled;
}

export function getCurrentCompanyByDriverProfiles(driverProfiles = []) {
  if (driverProfiles.length === 0) {
    return null;
  }

  const firstCompletelyActiveProfile = driverProfiles.find(
    isProfileCompletelyActive
  );
  if (firstCompletelyActiveProfile !== undefined) {
    return String(firstCompletelyActiveProfile.fleet.id);
  }

  const firstPartiallyActiveProfile = driverProfiles.find(
    isProfilePartiallyActive
  );
  if (firstPartiallyActiveProfile !== undefined) {
    return String(firstPartiallyActiveProfile.fleet.id);
  }

  return String(driverProfiles[0].fleet.id);
}
