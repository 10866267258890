import { TagTypes } from "../TagTypes";

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      getDriver: build.query({
        query: (id) => ({
          url: `drivers/me/${id}`,
        }),
      }),

      getDriverById: build.query({
        query: (id) => ({
          url: `drivers/${id}?include[]=lastPayout`,
        }),
      }),

      getDriverStripePayouts: build.query({
        query: (id) => ({
          url: `drivers/${id}/stripe-payouts`,
        }),
      }),

      getDriverDocuments: build.query({
        providesTags: [TagTypes.DRIVER_DOCUMENTS],
        query: (id) => ({
          url: `drivers/${id}/documents`,
        }),
      }),
      getDriverCustomDocumentTypes: build.query({
        query: (id) => ({
          url: `drivers/${id}/custom-document-types`,
        }),
      }),

      getDriverEarnings: build.mutation({
        query: ({ driverProfileId, payment_types, timeframe }) => ({
          data: {
            payment_types,
            timeframe,
          },
          url: `drivers/${driverProfileId}/earning-breakdown`,
          method: "POST",
        }),
      }),

      createStripeBankAccounts: build.mutation({
        query: ({
          driverProfileId,
          country,
          currency,
          account_number,
          routing_number,
        }) => ({
          data: {
            country,
            currency,
            account_number,
            routing_number,
          },
          url: `drivers/${driverProfileId}/stripe-bank-accounts`,
          method: "POST",
        }),
      }),

      getStripeBankAccounts: build.mutation({
        query: ({ driverProfileId, password }) => ({
          method: "POST",
          url: `drivers/${driverProfileId}/get-stripe-bank-accounts`,
          data: { password },
        }),
      }),

      deleteStripeBankAccounts: build.mutation({
        query: ({ driverProfileId, bankAccountId, password }) => ({
          method: "DELETE",
          url: `drivers/${driverProfileId}/stripe-bank-accounts`,
          data: { password, bank_account_id: bankAccountId },
        }),
      }),

      getStripeKycUrl: build.mutation({
        query: ({ id, refresh_url, return_url, type }) => {
          return {
            url: `drivers/${id}/stripe-account-link`,
            method: "GET",
            params: {
              refresh_url,
              return_url,
              type,
            },
          };
        },
      }),

      changeDriverStatus: build.mutation({
        query: ({ driverId, is_active }) => ({
          url: `drivers/${driverId}/change-status`,
          data: {
            is_active,
          },
          method: "PATCH",
        }),
      }),

      drawdownAmount: build.mutation({
        invalidatesTags: [TagTypes.DRAWDOWN_RELATED],
        query: ({ id, amount }) => ({
          data: {
            amount,
          },
          url: `drivers/${id}/payout`,
          method: "POST",
        }),
      }),

      calculatePayoutFees: build.mutation({
        query: ({ id, amount }) => ({
          data: {
            amount,
          },
          url: `drivers/${id}/calculate-payout-fees`,
          method: "POST",
        }),
      }),

      getDriversList: build.query({
        keepUnusedDataFor: 0,
        query: (params, include = ["lastPayout"]) => {
          const paramsWithoutId = (({ id, ...p }) => p)(params);
          return {
            params: { ...paramsWithoutId, include },
            url: `drivers/fleet/${params.id}`,
          };
        },
        serializeQueryArgs: ({ endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems) => {
          const firstFleetInListId = newItems?.data?.[0]?.fleet_id;
          const firstFleetInCacheId = currentCache?.data?.[0]?.fleet_id;

          if (firstFleetInListId !== firstFleetInCacheId) {
            currentCache.data = [...(newItems?.data || [])];
          } else {
            currentCache.data = [
              ...currentCache.data,
              ...(newItems?.data || []),
            ];
          }

          currentCache.meta = newItems?.meta;
          currentCache.links = newItems?.links;
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
      }),

      getDriverInstantAvailableBalance: build.query({
        query: ({ driverId, currency }) => ({
          url: `drivers/${driverId}/instant-available-balance`,
          params: {
            currency,
          },
        }),
      }),

      postDocument: build.mutation({
        query: ({ id, data }) => ({
          data: data,
          url: `drivers/${id}/documents`,
          method: "POST",
        }),
      }),
      postBookingsReport: build.mutation({
        query: (props) => {
          const { id, data } = props;
          return {
            data: data,
            url: `drivers/${id}/bookings/report`,
            method: "POST",
          };
        },
      }),
    }),
  });
}
