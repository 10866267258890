import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companies: [],
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    getCompanies: (state, action) => {
      state.companies = action.payload;
    },
    addCompany: (state, action) => {
      state.companies.unshift(action.payload);
    },
  },
});

export const { getCompanies, addCompany } = companiesSlice.actions;

export default companiesSlice.reducer;
