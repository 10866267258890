import { Button, Tooltip } from "antd";
import { cloneElement } from "react";
import "./CustomButton.scss";
import { useTranslation } from "react-i18next";

export const CustomButton = ({
  children,
  disabled = false,
  Icon,
  onClick = () => {},
  className = "",
}) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={disabled ? () => {} : onClick}
      className={`custom-btn ${className}`.trim()}
    >
      <div className="btn-content">{children}</div>
      <Tooltip
        title={disabled ? t("field.exportBookingsError") : ""}
        placement="topLeft"
        overlayClassName="hint-error"
        className="hint-error"
      >
        {Icon &&
          cloneElement(Icon, {
            className: `btn-icon ${disabled ? "disabled" : ""}`,
          })}
      </Tooltip>
    </Button>
  );
};
