import { useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { changeLanguage } from "i18n";
import { useSelectedFleet } from "hooks/useSelectedFleet";

import { getDefaultFleetIdByDriverProfiles } from "./getDefaultFleetIdByDriverProfiles";

/**
 * Makes sure that a fleet is selected.
 * If none  fleet is selected, it selects the default fleet.
 * If out of scope fleet is selected, it selects the default fleet.
 */
export function SelectedFleetRequired({ children }) {
  const {
    selectFleetId,
    selectedFleetId,
    selectedFleet,
    driverProfiles,
    fleetsById,
  } = useSelectedFleet();

  useEffect(
    function selectDefaultFleetWhenNoneOrOutOfScopeSelected() {
      const hasOptionsToSelectFrom = driverProfiles.length > 0;
      if (!hasOptionsToSelectFrom) {
        return;
      }

      const isAnyFleetSelected = selectedFleetId !== null;
      const isSelectedFleetInScope = selectedFleetId in fleetsById;

      const hasValidFleetSelected =
        isAnyFleetSelected && isSelectedFleetInScope;
      if (hasValidFleetSelected) {
        return;
      }

      const defaultFleetId = getDefaultFleetIdByDriverProfiles(driverProfiles);
      selectFleetId(defaultFleetId);
    },
    [selectedFleetId, driverProfiles, fleetsById, selectFleetId]
  );

  useEffect(
    function changeLanguageWhenSelectedFleetChanges() {
      changeLanguage(selectedFleet?.locale);
    },
    [selectedFleet]
  );

  const ldClient = useLDClient();
  useEffect(
    function changeLdContextWhenSelectedFleetChanges() {
      const noFleetSelected = selectedFleetId === null;
      if (noFleetSelected) return;

      const ldContext = ldClient.getContext();
      const nextContextKey = String(selectedFleetId);

      const contextKeyDidNotChanged = ldContext?.key === nextContextKey;
      if (contextKeyDidNotChanged) return;

      ldClient.identify({
        kind: "fleet",
        key: nextContextKey,
      });

      return function shutdownLDClient() {
        ldClient.close();
      };
    },
    [selectedFleetId, ldClient]
  );

  return children;
}
