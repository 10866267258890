import { Navigate, useParams } from "react-router-dom";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { LoadingComp } from "components/LoadingComp";

import { DriverDocumentForm } from "./components/DriverDocumentForm";
import { DriverDocumentLayout } from "./DriverDocumentLayout";
import { useQueryDocumentTypeById } from "./useQueryDocumentTypeById";
import { useQueryDriverCustomDocumentTypes } from "./useQueryDocumentTypes";
import { routes } from "routes";

export function DriverDocumentPage({ isEditPage = false } = {}) {
  const { documentTypeId } = useParams();

  const { selectedFleetId, selectedDriverProfile } = useSelectedFleet();
  const { data: currentDocumentTypeDetails, isLoading } =
    useQueryDocumentTypeById({
      documentTypeId,
      currentCompanyId: selectedFleetId,
    });
  const {
    predefinedDriverDocumentTypes,
    customDriverDocumentTypes,
    predefinedVehicleDocumentTypes,
    customVehicleDocumentTypes,
  } = useQueryDriverCustomDocumentTypes(selectedDriverProfile?.id);

  const allDocumentsIds = predefinedDriverDocumentTypes
    .concat(
      customDriverDocumentTypes,
      predefinedVehicleDocumentTypes,
      customVehicleDocumentTypes
    )
    .map(({ id }) => id.toString());

  if (!allDocumentsIds.includes(documentTypeId)) {
    return <Navigate to={routes.documents.getURL()} replace />;
  }

  if (!currentDocumentTypeDetails || isLoading) {
    return (
      <DriverDocumentLayout>
        <LoadingComp loading fullScreen />
      </DriverDocumentLayout>
    );
  }

  return (
    <DriverDocumentLayout>
      <DriverDocumentForm
        isEditPage={isEditPage}
        documentTypeId={currentDocumentTypeDetails.id}
        documentTypeDetails={currentDocumentTypeDetails}
      />
    </DriverDocumentLayout>
  );
}
